const form = [
    {
      name: '微信手续费（%）',
      key: 'wechatFee',
      type: 'input'
    },
    {
        name: '支付宝手续费（%）',
        key: 'alipayFee',
        type: 'input'
      },
      {
        name: '银联手续费（%）',
        key: 'unionPayFee',
        type: 'input'
      },
  ]
  export default {
    type: 'form',
    url: '/config/farmOrderHandlingFee/detail',
    edit: '/config/farmOrderHandlingFee/save',
    data: form
  }
  